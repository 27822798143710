import { defineStore } from 'pinia'

export const useImageStore = defineStore('image', {
  getters: {
    getImages: () => {
      return (type: string, imagesData: any[]): any[] => imagesData.filter((x: any) => x.meta.type === type)
    },
    isWebp: () => {
      return (path: string): boolean => path.endsWith('.webp')
    }
  }
})
